import { ActionIcon, Badge, Group, Menu, Tooltip } from "@mantine/core";

import {
  CancelBtn,
  CreateBillBtn,
  DeliverBtn,
  ReadyBtn,
  ReceivedDeliveryForm,
} from "./DfBtns";
import {
  CLIENT,
  PRICE_TTC,
  PRODUCTS,
  SCHEDULED_FOR,
  SERVICES,
  SETTINGS,
  STATUS,
  TAXI,
} from "Constant";
import ReadSheetBtn from "Components/MantineDataTableBtns/ReadSheetBtn";
import ReadPageBtn from "Components/MantineDataTableBtns/ReadPageBtn";
import Translate from "Components/Translate";
import { IconDots } from "@tabler/icons-react";
import SettingsReadBtn from "Components/MantineDataTableBtns/SettingsReadBtn";
import { createdAt } from "Constant/objs";

// API
export const API = {
  list: "/back/deliveryForms",
  get: "/back/deliveryForm",
  edit: "/back/deliveryForm/",
  create: "/back/deliveryForm",
};

// Columns
export const COLUMNS = [
  {
    name: "N° delivary form",
    selector: "df_number",
  },

  {
    accessorFn: (originalRow) => originalRow.po_number,
    id: "po_number",
    header: "N° purchase order",
    size: 200,

    Cell: ({ row }) => {
      const { po_number, po_code } = row.original;
      const path = `/purchase-order/${po_code}`;
      if (po_code) return <ReadSheetBtn read={path} title={po_number} />;
    },
  },

  {
    accessorFn: (originalRow) => originalRow.estimate_number,
    id: "estimate_number",
    header: "N° estimate",
    size: 200,

    Cell: ({ row }) => {
      const { estimate_number, estimate_code } = row.original;
      const path = `/estimate/${estimate_code}`;
      if (estimate_code)
        return <ReadSheetBtn read={path} title={estimate_number} />;
    },
  },

  {
    header: CLIENT,
    accessorFn: (originalRow) => originalRow.client,
    id: "client",

    size: 300,
    Cell: ({ row }) => {
      const { client, code_client } = row.original;
      const path = `/client/${code_client}`;

      return <ReadSheetBtn read={path} title={client} />;
    },
  },
  {
    header: TAXI,
    accessorFn: (originalRow) => originalRow.taxi,
    id: "taxi",

    size: 300,
    Cell: ({ row }) => {
      const { taxi, code_taxi } = row.original;
      const path = `/taxi/${code_taxi}`;

      return <ReadSheetBtn read={path} title={taxi} />;
    },
  },
  {
    accessorFn: (originalRow) => originalRow.status,
    id: "status",

    filterFn: "equals",
    filterVariant: "select",
    mantineFilterSelectProps: {
      data: [
        { value: "canceled", label: "Annulé" },
        { value: "draft", label: "Brouillon" },
        { value: "in_preparation", label: "En preparation" },
        { value: "to_pickup", label: "A enlever" },
        { value: "in_delivery", label: "En livraison" },
        { value: "received", label: "Reçu" },
      ],
    },
    header: STATUS,

    Cell: ({ row }) => {
      const { status } = row.original;

      const color = getColorFromStatus(status);

      return (
        <Badge color={color} size="lg">
          {status}
        </Badge>
      );
    },
  },

  {
    name: SCHEDULED_FOR,
    selector: "expected_delivery_date",
    enableSorting: true,
  },
  { name: PRODUCTS, selector: "count_products", enableSorting: true },
  { name: SERVICES, selector: "count_services", enableSorting: true },

  {
    name: "Price",
    selector: "price",
  },
  {
    name: PRICE_TTC,
    selector: "price_ttc",
  },
  createdAt,
  {
    // header: "Actions",
    id: "actions",
    button: true,
    size: 50,
    Cell: ({ row }) => {
      const {
        code,
        df_number,
        to_pickup = false,
        to_cancel = false,
        to_deliver = false,
        to_bill = false,
        to_receive = false,
      } = row.original;

      const cancelUrl = `/back/deliveryForm/${code}`;
      const urlReady = `/back/deliveryForm/${code}/ready`;
      const urlDeliver = `/back/deliveryForm/${code}/inDelivery`;
      const createBillUrl = `/back/bill/${code}`;
      const urlReceived = `/back/deliveryForm/${code}/received`;

      return (
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Tooltip label={<Translate>{SETTINGS}</Translate>}>
              <ActionIcon size="lg" variant="subtle" color="gray">
                <IconDots color="gray" />
              </ActionIcon>
            </Tooltip>
          </Menu.Target>

          <Menu.Dropdown>
            <SettingsReadBtn read={`/delivery-form/${code}`} />

            <CreateBillBtn url={createBillUrl} disabled={!to_bill} />
            <ReadyBtn url={urlReady} disabled={!to_pickup} />
            <ReceivedDeliveryForm url={urlReceived} disabled={!to_receive} />
            <DeliverBtn url={urlDeliver} disabled={!to_deliver} />
            <CancelBtn url={cancelUrl} disabled={!to_cancel} />
          </Menu.Dropdown>
        </Menu>
      );
    },
  },
];

const getColorFromStatus = (status) => {
  const statusColors = {
    Annulé: "red",
    Brouillon: "gray",
    "En preparation": "yellow",
    "A enlever": "orange",
    "En livraison": "blue",
    Reçu: "green",
  };

  return statusColors[status] || "gray";
};
