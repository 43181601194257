import { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SegmentedControl, Divider } from "@mantine/core";
import { AiOutlineIdcard } from "react-icons/ai";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";

import useFetchData from "hooks/useFetchDataWithDep";

import Loading from "Components/Loading/Loading";
import NotFound from "Components/NotFound";
import HeaderTabs from "Components/Header/HeaderTabs";
import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";
import Breadcrumbs from "Components/Breadcrumbs/SheetOf";

import TariffTable from "./TariffTable";
import ParameterTable from "./ParameterTable";
import FlatfeeTable from "./FlatfeeTable";

import {
  TARIFF,
  PARAMETER,
  FLATFEE,
  CREATED_AT,
  UPDATED_AT,
  TIME_ZONE,
  PAUSE,
  EDIT_PROFILE,
  PROFILE,
} from "Constant";

import { useDispatch, useSelector } from "react-redux";
import { changeModalType } from "../../../redux/modal";
import { toggleRefresh } from "../../../redux/searchLink";
import { changeCurrentRow } from "../../../redux/currentRow";

import ModalApp from "Components/ModalApp";
import EditItem from "Components/EditItemWithZod";
import { EDIT_ITEMS } from "../data";

const ReadProfile = () => {
  const { t } = useTranslation();
  const { code } = useParams();
  const dispatch = useDispatch();
  const { refresh } = useSelector((state) => state.searchLink);

  const url = `/back/profile/${code}`;

  const changeState = () => {
    const selectedRow = {
      getLink: url,
      putLink: url,
    };
    dispatch(changeModalType("editProfile"));
    dispatch(changeCurrentRow(selectedRow));
  };

  const [state, setState] = useState("tarif");

  const componants = {
    tarif: <TariffTable profileCode={code} />,
    parameter: <ParameterTable profileCode={code} />,
    flatfee: <FlatfeeTable profileCode={code} />,
  };

  const { data, isLoading, isError } = useFetchData(url, refresh);

  if (isLoading) return <Loading />;

  if (isError) return <NotFound />;

  const profileDetails = getProfileDetails(data);

  const breadCrumbsProps = [`${profileDetails?.label}`];

  const requiredKeys = [
    { key: "timezone", name: TIME_ZONE, icon: <AiOutlineIdcard /> },
    { key: "created_at", name: CREATED_AT, icon: <AiOutlineIdcard /> },
    { key: "updated_at", name: UPDATED_AT, icon: <AiOutlineIdcard /> },

    { key: "pause", name: PAUSE, icon: <AiOutlineIdcard /> },
    {
      key: "active",
      name: "Active",
      icon: <AiOutlineIdcard />,
      btn: (
        <ActiveBtn
          patch={`/back/profile/${code}`}
          state={profileDetails.active}
        />
      ),
    },
  ];

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        {/* <Button
          label="Add new value"
          icon="pi pi-plus"
          severity="success"
          onClick={() => changeState("createTarif")}
          raised
        /> */}

        <Button
          label="Edit"
          icon="pi pi-pencil"
          severity="info"
          onClick={() => changeState("editTarif")}
          // disabled={true}
        />

        <Button
          label="Refresh"
          icon="pi pi-sync"
          severity="secondary"
          raised
          onClick={() => dispatch(toggleRefresh())}
        />
      </div>
    );
  };

  return (
    <>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <Toolbar
            className="mb-4 bg-white text-black border border-gray-300"
            start={leftToolbarTemplate}
          />

          <Row style={{ display: "flex" }}>
            <Col md="3">
              {!isLoading && (
                <HeaderTabs
                  title={breadCrumbsProps}
                  objectData={profileDetails}
                  requiredKeys={requiredKeys}
                />
              )}
            </Col>
            <Col md="9">
              <Card className="mb-0">
                <CardBody>
                  <SegmentedControl
                    transitionDuration={200}
                    transitionTimingFunction="linear"
                    color="yellow"
                    fullWidth
                    size="md"
                    value={state}
                    onChange={setState}
                    data={[
                      { label: TARIFF, value: "tarif" },
                      { label: PARAMETER, value: "parameter" },
                      { label: FLATFEE, value: "flatfee" },
                    ]}
                  />
                  <Divider my="md" />

                  {componants[state]}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <ModalApp
        type="editProfile"
        title={`${t(EDIT_PROFILE)} ${profileDetails?.label}`}
      >
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>
    </>
  );
};

export default ReadProfile;

const getProfileDetails = (data) => {
  try {
    return data.data.results.data;
  } catch {
    return {};
  }
};
