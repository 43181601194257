import FilterSelectBar from "Components/FilterInputs/FilterSelectBar";
import { createdAt, updatedAt } from "Constant/objs";
import { getCodeLabel, getTitleCodeLabel } from "helper/getOptions";
import StatusColumn from "Components/MantineDataTableBtns/StatusColumn";
import { Group } from "@mantine/core";
import EditBtn from "Components/MantineDataTableBtns/EditBtn";

// Urls
export const API = {
  list: "/back/sosButtons",
  choice: "/back/sosButtons/choices",
  Serial: "/back/sosButtons/serials",
  create: "/back/sosButton",
  get: "/back/sosButton/",
  edit: "/back/sosButton/",
  patch: "/back/sosButton/",
  import: "/back/sosButtons",
};

// Columns
export const COLUMNS = [
  {
    accessorFn: (originalRow) => originalRow.status_label,
    id: "status",
    header: "Status",
    Filter: ({ column, header, table }) => {
      try {
        return (
          <FilterSelectBar
            url={"/back/statuses/material"}
            column={column}
            header={header}
            table={table}
          />
        );
      } catch {
        return <></>;
      }
    },
    Cell: ({ row }) => {
      const { status_code, status } = row.original;
      return <StatusColumn code={status_code} label={status} />;
    },
  },
  {
    name: "Number",
    selector: "serial_number",
  },
  createdAt,
  updatedAt,
  {
    id: "actions",
    size: 10,
    Cell: ({ row }) => {
      const { code, serial_number } = row.original;
      return (
        <Group gap="xs">
          <EditBtn read={API.get + code} extra={serial_number} />
        </Group>
      );
    },
  },
];

// Search
export const SEARCH_ITEMS = [
  {
    name: "status",
    title: "status",
    inputForm: "SelectApiInput",
    required: false,
    optionsApi: "/back/statuses/material",
    getOptions: getCodeLabel,
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/sos_button",
    getOptions: getTitleCodeLabel,
  },
  {
    title: "Amount",
    name: "amount",
    inputForm: "SimpleInput",
  },
];

export const EDIT_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/sos_button",
    getOptions: getTitleCodeLabel,
  },
  {
    title: "Amount",
    name: "amount",
    inputForm: "SimpleInput",
  },
];
