import FilterSelectBar from "Components/FilterInputs/FilterSelectBar";
import { createdAt, updatedAt } from "Constant/objs";
import { getCodeLabel, getTitleCodeLabel } from "helper/getOptions";
import StatusColumn from "Components/MantineDataTableBtns/StatusColumn";
import { Group } from "@mantine/core";
import EditBtn from "Components/MantineDataTableBtns/EditBtn";
import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";
import { STATUS } from "Constant";

// Urls
export const API = {
  list: "/back/pulseFilters",
  choice: "/back/pulseFilter/choices",
  Serial: "/back/pulseFilter/serials",
  create: "/back/pulseFilters",
  get: "/back/pulseFilter/",
  edit: "/back/pulseFilter/",
  patch: "/back/pulseFilter/",
  import: "/back/pulseFilter",
};

// Columns
export const COLUMNS = [
  {
    accessorFn: (originalRow) => originalRow.status_label,
    id: "status",
    header: STATUS,
    Filter: ({ column, header, table }) => {
      try {
        return (
          <FilterSelectBar
            url={"/back/statuses/material"}
            column={column}
            header={header}
            table={table}
          />
        );
      } catch {
        return <></>;
      }
    },
    Cell: ({ row }) => {
      const { status_code, status } = row.original;
      return <StatusColumn code={status_code} label={status} />;
    },
  },
  {
    name: "Number",
    selector: "serial_number",
  },
  {
    name: "Model",
    selector: "model",
  },
  createdAt,
  updatedAt,
  {
    id: "actions",
    size: 10,
    Cell: ({ row }) => {
      const { code, active, serial_number, editable } = row.original;
      return (
        <Group gap="xs">
          <EditBtn
            read={API.get + code}
            extra={serial_number}
            disabled={!editable}
          />
          <ActiveBtn patch={API.patch + code} state={active} />
        </Group>
      );
    },
  },
];

// Search
export const SEARCH_ITEMS = [
  {
    name: "status",
    title: "status",
    inputForm: "SelectApiInput",
    required: false,
    optionsApi: "/back/statuses/material",
    getOptions: getCodeLabel,
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/pulse_filter",
    getOptions: getTitleCodeLabel,
  },
  {
    title: "Amount",
    name: "amount",
    inputForm: "SimpleInput",
  },
];

export const EDIT_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/pulse_filter",
    getOptions: getTitleCodeLabel,
  },
  {
    title: "Amount",
    name: "amount",
    inputForm: "SimpleInput",
  },
];
