import React from "react";

import { Fragment } from "react";

import DataTables from "Components/DataTables/DataTable";
import EditItem from "Components/EditItemWithZod";
import ModalApp from "Components/ModalApp";
import AddItem from "Components/AddItemWithZod";

import { API, CREATE_ITEMS, EDIT_ITEMS, COLUMNS } from "../data/tariff";

import { CREATE_TARIFF, EDIT_TARIFF } from "Constant";
import TariffDetails from "./TariffDetails";

const TariffTable = ({ profileCode }) => {
  const list = API.list(profileCode);

  const RenderDetailPanel = ({ data }) => {
    const { code } = data.original;

    return <TariffDetails code={code} />;
  };

  return (
    <Fragment>
      <DataTables
        newColumns={COLUMNS}
        link={list}
        // renderDetailPanel={({ row }) => <RenderDetailPanel data={row} />}
      />

      <ModalApp type="create" title={CREATE_TARIFF}>
        <AddItem addURL={API.create(profileCode)} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_TARIFF}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default TariffTable;
