import { Group } from "@mantine/core";
import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";
import EditBtn from "Components/MantineDataTableBtns/EditBtn";
import ReadPageBtn from "Components/MantineDataTableBtns/ReadPageBtn";
import { LABEL } from "Constant";

// Urls
export const API = {
  list: (p) => `/back/profile/${p}/tariffs`,
  get: (p) => `/back/tariff/${p}`,
  create: (p) => `/back/profile/${p}/tariff`,
  edit: (p) => `/back/tariff/${p}`,
};

// Columns
export const COLUMNS = [
  {
    name: LABEL,
    selector: "label",
  },
  {
    name: "Owner share",
    selector: "owner_share",
  },
  {
    name: "Led",
    selector: "led",
  },
  { name: "Date begin", selector: "date_begin" },

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 70,
    Cell: ({ row }) => {
      const { code, active, label } = row.original;

      return (
        <Group gap="xs">
          <ActiveBtn patch={API.get(code)} state={active} />
          <ReadPageBtn read={`/tariff/${code}`} />
          <EditBtn read={API.get(code)} extra={label} />
        </Group>
      );
    },
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Owner share",
    placeholder: "Owner share",
    name: "owner_share",
    inputForm: "NumberInput",
  },
  {
    title: "Label",
    placeholder: "Label",
    name: "label",
    inputForm: "SimpleInput",
  },
  {
    title: "Led",
    placeholder: "led",
    name: "led",
    inputForm: "SimpleInput",
  },
];

export const EDIT_ITEMS = [
  {
    title: "Owner share",
    placeholder: "Owner share",
    name: "owner_share",
    inputForm: "NumberInput",
  },
  {
    title: "Label",
    placeholder: "Label",
    name: "label",
    inputForm: "SimpleInput",
  },
  {
    title: "Led",
    placeholder: "led",
    name: "led",
    inputForm: "SimpleInput",
  },
];
