import FilterSelectBar from "Components/FilterInputs/FilterSelectBar";

import { activeObj, createdAt, updatedAt } from "Constant/objs";
import {
  getCodeLabel,
  getOptionNumber,
  getTitleCodeLabel,
} from "helper/getOptions";
import StatusColumn from "Components/MantineDataTableBtns/StatusColumn";
import { STATUS } from "Constant";
import { Group } from "@mantine/core";
import EditBtn from "Components/MantineDataTableBtns/EditBtn";
import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";

// Urls
export const API = {
  list: "/back/luminouss",
  GET_LUMINOUS_CHOICE: "/back/luminouss/choices",
  Serial: "/back/luminouss/serials",
  create: "/back/luminous",
  edit: "/back/luminous/",
  GET_LUMINOUS: "/back/luminous/",
  patch: "/back/luminous/",
  IMPORT_LUMINOUS: "/back/luminouss",
};

// Columns
export const COLUMNS = [
  {
    accessorFn: (originalRow) => originalRow.status_label,
    id: "status",

    header: STATUS,

    // filterFn: "equals",
    Filter: ({ column, header, table }) => {
      try {
        return (
          <FilterSelectBar
            url={"/back/statuses/material"}
            column={column}
            header={header}
            table={table}
          />
        );
      } catch {
        return <></>;
      }
    },

    Cell: ({ row }) => {
      const { status_code, status } = row.original;
      return <StatusColumn code={status_code} label={status} />;
    },
  },
  {
    name: "Number",
    selector: "serial_number",
  },

  { name: "Count leds", selector: "count_leds" },
  createdAt,
  updatedAt,
  {
    id: "actions",
    size: 10,
    Cell: ({ row }) => {
      const { code, active, serial_number, editable } = row.original;
      return (
        <Group gap="xs">
          <EditBtn
            read={API.get + code}
            extra={serial_number}
            disabled={!editable}
          />
          <ActiveBtn patch={API.patch + code} state={active} />
        </Group>
      );
    },
  },
];

// Search
export const SEARCH_ITEMS = [
  activeObj,
  { name: "brand", inputForm: "SelectInputGroups" },
  {
    name: "status",
    title: "status",
    inputForm: "SelectApiInput",
    required: false,

    optionsApi: "/back/statuses/material",
    getOptions: getCodeLabel,
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/luminous",
    getOptions: getTitleCodeLabel,
  },
  {
    title: "Firmware",
    name: "firmware",
    inputForm: "SelectApiInput",
    optionsApi: "/back/firmwares/choices?category=luminous",
    getOptions: getCodeLabel,
  },
  {
    name: "external",
    title: "External",
    inputForm: "SelectInput",
    options: [
      {
        value: true,
        label: "external",
        name: "external",
      },
      {
        value: false,
        label: "not external",
        name: "external",
      },
    ],
  },
  {
    title: "Count leds",
    name: "count_leds",
    inputForm: "SelectInput",
    options: getOptionNumber(1, 100),
    number: true,
  },
];
export const EDIT_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/luminous",
    getOptions: getTitleCodeLabel,
  },
  {
    title: "Firmware",
    name: "firmware",
    inputForm: "SelectApiInput",
    optionsApi: "/back/firmwares/choices?category=luminous",
    getOptions: getCodeLabel,
  },
  {
    name: "external",
    title: "External",
    inputForm: "SelectInput",
    options: [
      {
        value: "true",
        label: "external",
        name: "external",
      },
      {
        value: "false",
        label: "not external",
        name: "external",
      },
    ],
  },
  {
    title: "Count leds",
    name: "count_leds",
    inputForm: "SelectInput",
    options: getOptionNumber(1, 100),
    number: true,
  },
];
