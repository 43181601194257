import Translate from "Components/Translate";
import { ALL } from "Constant";
import { getData } from "helper/getData";
import useFetchData from "hooks/useFetchDataWithDep";
import React from "react";

const FilterSelectBar = ({ column, table, url }) => {
  const { data, isLoading, isError } = useFetchData(url);

  if (isLoading) return null;
  if (isError) return null;

  const options = getData(data)
    ? Array.from(
        new Map(
          getData(data).map((item) => [
            item.code || item.value,
            {
              value: item.code || item.value || "", // Adjust key as needed
              label: item.label || item.name || "", // Adjust key as needed
            },
          ])
        ).values()
      )
    : [];

  const handleOnChange = (event) => {
    const value = event.target.value;

    if (!value) {
      // Clear the filter if no value is selected
      table.setColumnFilters([]);
      return;
    }

    table.setColumnFilters([
      {
        id: column.id,
        value,
      },
    ]);
  };

  return (
    <select
      onChange={handleOnChange}
      defaultValue="" // Default placeholder option
      aria-label="Filter select"
    >
      <option value="">
        <Translate>{ALL}</Translate>
      </option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default FilterSelectBar;
