export const getPurchaseRequests = (status) => {
  const statusColors = {
    CANCELED: "red",
    PENDING: "green",
    DRAFT: "indigo",
    FINISHED: "blue",
    // "En preparation": "yellow",

    // "En livraison": "orange",
    // Pret: "blue",
    // Clôturé: "cyan",
  };

  return statusColors[status] || "grape"; // Default color if status doesn't match
};

const STATUS_CODE_COLOR = {
  draft: "indigo",
  pending: "orange",
  canceled: "red",
  finished: "blue",
  under_examination: "yellow",
  active: "green",
  to_pickup: "cyan",
  picked_up: "lime",
  received: "teal",
  rejected: "red",
  activated: "green",
  in_stock: "blue",
  in_test: "purple",
  removed: "gray",
  in_repair: "orange",
  in_preparation: "yellow",
  in_primitive: "pink",
  in_transit: "cyan",
  repaired: "green",
  sold: "green",
  external: "brown",
  draft: "indigo",
  in_delivery: "teal",
  closed: "gray",
  ready: "blue",
  paid: "green",
  published: "lime",
  confirmed: "cyan",
  created: "purple",
  sent: "orange",
  delivred: "lime",
  opened: "yellow",
  returned: "red",
  declined_by_customer: "red",
  declined_by_taxi: "red",
  incident_customer: "orange",
  incident_taxi: "orange",
  sent_to_operator: "blue",
  received_by_operator: "teal",
  received_by_taxi: "lime",
  accepted_by_taxi: "green",
  accepted_by_customer: "green",
  customer_on_board: "cyan",
  timeout_taxi: "purple",
  timeout_customer: "purple",
  failure: "red",
  busy: "orange",
  free: "green",
  off: "gray",
  on: "lime",
};

export const getStatusColor = (code) => {
  const smallString = code ? code.toLowerCase() : null;
  if (smallString) return STATUS_CODE_COLOR[smallString] || "gray";
  return "gray";
};
